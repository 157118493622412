@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}
html {
  /*overflow-y: auto;*/
}
html,
body {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}
body,
select,
textarea,
input {
  font: 14px/100% Apple SD Gothic Neo, "Apple SD 산돌고딕 Neo", "Roboto",
    "맑은 고딕", "Malgun Gothic", "Apple Gothic", NanumGothic, "나눔고딕", Dotum,
    "돋움", gulim, "굴림", verdana, sans-serif;
  color: #333;
  border: 0;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
table,
th,
td,
embed,
object,
textarea,
a,
img {
  padding: 0;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table img {
  vertical-align: middle;
}
select,
input,
table tr td,
table tr th {
  vertical-align: middle;
}
form,
fieldset,
abbr,
acronym {
  border: 0;
}
img {
  border: 0;
  vertical-align: top;
}
/* 이미지에 공간을 없애기 위해 vertical-align:top 사용 */
address,
caption,
cite,
code,
dfn,
em,
h1,
h2,
h3,
h4,
h5,
h6,
th,
var {
  font-weight: normal;
  font-style: normal;
}
ol,
ul,
li {
  list-style: none;
}
table td a {
  display: inline-block;
}
table caption {
  display: none;
}
a {
  text-decoration: none;
}
input[type="image"] {
  vertical-align: top;
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin border-box() {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin border-box() {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/*
    Example usage:
    @include animation(10s, 5s, changecolour)
 */
@mixin animation($delay, $duration, $animation, $iteration, $direction) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-iteration-count: $iteration;
  -webkit-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -webkit-animation-direction: $direction;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-iteration-count: $iteration;
  -moz-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -moz-animation-direction: $direction;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-iteration-count: $iteration;
  -o-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -o-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-iteration-count: $iteration;
  animation-fill-mode: both; /* this prevents the animation from restarting! */
  animation-direction: $direction; // alternate
}

@include keyframe(fadeout) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}


.ir-pm {
  display: block;
  overflow: hidden;
  font-size: 1px;
  line-height: 0;
  color: transparent;
}
.modal-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.modal-cell {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  height: 100%;
}
