@import "reset";
.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.container > canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

body {
  background-color: #000;
}
header {
  display: none;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  .logo {
    position: fixed;
    top: 14px;
    left: 10px;
    a {
      display: block;
      position: relative;
      font-size: 16px;
      line-height: 1.3;
      color: #000;
      font-weight: bold;
      width: 80px;
      height: 32px;
      transition: color 2s ease;
      -webkit-transition: color 2s ease;
    }
    .txt {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: opacity 2s ease;
      -webkit-transition: opacity 2s ease;
    }
    .txt-black {
      opacity: 1;
    }
    .txt-white {
      opacity: 0;
    }
  }
  .side {
    position: fixed;
    top: 20px;
    right: 20px;
    .btn-about {
      font-size: 14px;
      line-height: 1.3;
      color: #000;
      font-weight: bold;
      opacity: 0.9;
      transition: color 1s ease;
      -webkit-transition: color 1s ease;
    }
  }
  &.white {
    .logo {
      a {
        color: #fff;
        .txt-black {
          opacity: 0;
        }
        .txt-white {
          opacity: 1;
        }
      }
    }
    .side {
      .btn-about {
        color: #fff;
      }
    }
  }
}
.indicators {
  position: fixed;
  top: 0;
  left: 0;
  .indicator {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    box-sizing:border-box;
    -webkit-tap-highlight-color: transparent;
    .group {
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      -webkit-transform-origin: 50% 50%;
    }
    .center {
      display: block;
      position: absolute;
      top: 37px;
      left: 37px;
      width: 6px;
      height: 6px;
      background-color: #fff;
      box-sizing:border-box;
      opacity: 0;
      border-radius:10px;
      animation:fadeInOuteOut 1s 0s infinite linear alternate;
      -webkit-animation:fadeInOut 1s 0s infinite linear alternate;
    }
    .circle {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      border: 2px solid #fff;
      text-align: center;
      line-height: 80px;
      pointer-events: none;
      box-sizing:border-box;
      border-radius:50px;
      opacity:1;
      transform:scale(0, 0);
      -webkit-transform:scale(0, 0);
    }
    .circle-0 {
      animation:scaleOut 3s 0.1s infinite linear normal;
      -webkit-animation:scaleOut 3s 0.1s infinite linear normal;
    }
    .circle-1 {
      animation:scaleOut 3s 1.1s infinite linear normal;
      -webkit-animation:scaleOut 3s 1.1s infinite linear normal;
    }
    .circle-2 {
      animation:scaleOut 3s 2.1s infinite linear normal;
      -webkit-animation:scaleOut 3s 2.1s infinite linear normal;
    }
    .text {
      position: absolute;
      top: 27px;
      left: 25px;
      width: 50px;
      display: none;
      text-align: center;
      color: #fff;
      font-size: 0.9rem;
      font-weight: bold;
      opacity: 0.65;
      user-select: none;
    }
    &.black {
      .center {
        background-color: #000;
      }
      .circle {
        border: 2px solid #000;
      }
      .text {
        color: #000;
      }
    }
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes scaleOut {
  0% {
    transform:scale(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform:scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes scaleOut {
  0% {
    -webkit-transform:scale(0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    -webkit-transform:scale(1, 1);
    opacity: 0;
  }
}


.intro-view {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  h3 {
    font-size: 30px;
    opacity: 0;
  }
}
.load-view {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  .card {
    width: 100px;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    .title {
      font-size: 20px;
      line-height: 1.6;
      font-weight: bold;
    }
    .count {
      padding-top: 5;
      font-size: 14px;
      line-height: 1.6;
    }
  }
}
.texts {
  z-index: 5;
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  transform: translateY(0px);
  text-align: center;
  pointer-events: none;
  padding: 0 10px;
  @include border-box();
  @include translate(0px, 0px);
  .text {
    opacity: 0;
    font-size: 15px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.4);
    font-family: "Roboto Slab", serif;
    @include translate(0px, 14px);
    @media screen and (max-width: 414px) {
    }
  }
}

.end {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  .card {
    opacity: 0;
    margin: auto;
    position: relative;
    display: inline-block;
    max-width: 400px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 80px 20px 60px;
    box-sizing: border-box;
    @include translate(0, 15px);
    li {
      text-align: left;
      &:first-child {
        margin-bottom: 30px;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .job {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #000;
      padding-bottom: 5px;
      font-weight: bold;
    }
    .name {
      display: block;
      font-size: 26px;
      line-height: 1.5;
      font-weight: bold;
      color: #000;
      box-sizing: border-box;
    }
    a.name{
      border-bottom: 4px solid #333;
    }

    // mobile
    @media screen and (min-width: 400px) {
      li:first-child {
        margin-bottom: 30px;
      }
      .job {
        float: left;
        width: 120px;
        line-height: 40px;
        padding-bottom: 0;
      }
      .name {
        float: left;
        line-height: 40px;
      }
    }
  }
  &.show {
    .bg {
      opacity: 1;
    }
    .card {
      opacity: 1;
      //@include translate(0, 0);
    }
  }
}

.modal {
  z-index: 20;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  .bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
  }
  .card {
    opacity: 0;
    margin: auto;
    position: relative;
    display: inline-block;
    max-width: 400px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 80px 20px 60px;
    box-sizing: border-box;
    @include translate(0, 15px);
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      .inner {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 40px;
        height: 40px;
      }
      .bar-h {
        position: absolute;
        bottom: 0;
        left: 19px;
        width: 2px;
        height: 0;
        background-color: #000;
        transition: height 0.4s ease, transform 0.6s ease-in-out 0.15s;
        -webkit-transition: height 0.4s ease, transform 0.6s ease-in-out 0.15s;
      }
      .bar-v {
        position: absolute;
        bottom: 0;
        left: 19px;
        width: 2px;
        height: 0;
        background-color: #000;
        transition: height 0.4s ease, transform 0.6s ease-in-out 0.15s;
        -webkit-transition: height 0.4s ease, transform 0.6s ease-in-out 0.15s;
      }
      &.show {
        .bar-h {
          height: 40px;
          @include rotate(45);
        }
        .bar-v {
          height: 40px;
          @include rotate(135);
        }
      }
      &.fadeOut {
        .bar-h {
          height: 0;
          @include rotate(0);
          transition: height 0.3s ease 0.1s, transform 0.2s ease;
          -webkit-transition: height 0.3s ease 0.1s, transform 0.2s ease;
        }
        .bar-v {
          height: 0;
          @include rotate(0);
          transition: height 0.3s ease 0.1s, transform 0.2s ease;
          -webkit-transition: height 0.3s ease 0.1s, transform 0.2s ease;
        }
      }
    }
    li {
      text-align: left;
      &:first-child {
        margin-bottom: 30px;
      }
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .job {
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: #000;
      padding-bottom: 5px;
      font-weight: bold;
    }
    .name {
      display: block;
      font-size: 26px;
      line-height: 1.5;
      font-weight: bold;
      color: #000;
      
      box-sizing: border-box;
    }
    a.name{
      border-bottom: 4px solid #333;
    }

    // mobile
    @media screen and (min-width: 400px) {
      .btn-close {
        right: 10px;
      }
      li:first-child {
        margin-bottom: 30px;
      }
      .job {
        float: left;
        width: 120px;
        line-height: 40px;
        padding-bottom: 0;
      }
      .name {
        float: left;
        line-height: 40px;
      }
    }
  }
  &.white {
    .bg {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .card {
      .btn-close {
        .bar-h {
          background-color: #fff;
        }
        .bar-v {
          background-color: #fff;
        }
      }
      .job {
        color: #fff;
      }
      .name {
        color: #fff;
        border-bottom-color: #fff;
      }
    }
  }
  &.show {
    .bg {
      opacity: 1;
    }
    .card {
      opacity: 1;
      @include translate(0, 0);
    }
  }
}
